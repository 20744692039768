import React, { Component } from 'react';
import {  Link } from 'react-router-dom';
import './../../App.css'

class NewPassword extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            message: "",
            isLoading: "",
        };
    };
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({isLoading: "Ei toiminnassa!"});
        this.setState({message: "Tätä ominaisuutta ei vielä tueta."});
    }
    
    render() {
        return (
            <div>
                <div className="content">
                    <h2 className="header">Unohditko salasanasi?</h2>
                    <div className="small-content">
                        Kirjoita tähän käyttäjätunnuksena toimiva sähköpostiosoitteesi. 
                        Lähetämme sinulle linkin, jota kautta pääset määrittämään uuden salasanan. 
                        Jos viestiä ei kuulu minuutin sisällä, tarkista myös roskapostikori.
                    </div>               
                    <form onSubmit={this.onSubmit} className="small-content">
                        <input type="email" name="email" value={this.state.email} onChange={this.onChange} placeholder="Sähköposti" />
                        <input type="submit" className="fadeIn fourth" value="Lähetä" />
                        <p>{this.state.isLoading}</p>
                        <p className="loginalert">{this.state.message}</p>  
                        <p>
                           <Link to="/">Takaisin</Link>
                        </p>
                    </form>
                </div>
            </div>
        );
    }

}

export default NewPassword;
