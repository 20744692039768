import infomaattiAPI from './../config/api'

function getCardList(project_id, token, card_url) {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + token,
        'Project-Id': project_id
    }

    var myHeaders = new Headers()
    for (var key in headers) {
        myHeaders.append(key, headers[key])
    }

    const url = infomaattiAPI.baseURL + 'cards/' + card_url
    const request = new Request(url, {
        method: 'GET',
        headers: myHeaders,
        cache: infomaattiAPI.cache
    })

    return fetch(request)
}

export function saveNewCard(project_id, token, card_url, formData) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + token,
        'Project-Id': project_id
    }

    var myHeaders = new Headers()
    for (var key in headers) {
        myHeaders.append(key, headers[key])
    }

    const url = infomaattiAPI.baseURL + 'cards/' + card_url
    const request = new Request(url, {
        method: 'POST',
        headers: myHeaders,
        cache: infomaattiAPI.cache,
        body: JSON.stringify(formData)
    })

    return fetch(request)
}

export function addPhoto(project_id, token, card_url, card_id, photoData) {
    const headers = {
        'Authorization': 'Basic ' + token,
        'Project-Id': project_id
    }

    var myHeaders = new Headers()
    for (var key in headers) {
        myHeaders.append(key, headers[key])
    }

    const url = infomaattiAPI.baseURL + 'cards/' + card_url + '/' + card_id + '/add_photo'
    const request = new Request(url, {
        method: 'POST',
        headers: myHeaders,
        body: photoData
    })

    return fetch(request)
}

export default getCardList

