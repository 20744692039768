import React from 'react'
import { useFieldArray, Controller } from 'react-hook-form'
import uuid from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faCameraRetro } from '@fortawesome/fontawesome-free-solid'
import PhotoAttachmentArray from './PhotoAttachmentArray'
import Camera from '../../CameraPopup'

export default ({ nestIndex, namePrefix, handleMediaChange, handleMediaDelete, control, register, setValue, getValues }) => {
    const formTextFieldArrayName = namePrefix + '.' + nestIndex + '.form_text_fields_attributes'

    const { fields, remove, append } = useFieldArray({
        control,
        name: formTextFieldArrayName
    })

    return (
        <>
            <div className="form-text-fields">
                {fields.map((item, index) => {
                    return (
                         <div key={item.id} className="form-text-field">
                            <>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" />}
                                        className="form-control"
                                        name={`${formTextFieldArrayName}.${index}.position`}
                                        control={control}
                                        defaultValue={index}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" />}
                                        className="form-control"
                                        name={`${formTextFieldArrayName}.${index}.uid`}
                                        control={control}
                                        defaultValue={uuid()}
                                    />
                                </div>
                            
                                <div className="form-group form-text-field">
                                    <label className="col-form-label">Kommentti</label>
                                    <Controller
                                        render={({ field }) => <textarea rows="3" cols="30" {...field} />}
                                        className="form-control comment"
                                        name={`${formTextFieldArrayName}.${index}.text_value`}
                                        control={control}
                                        defaultValue=""
                                    />

                                    <button type="button" className="btn btn-danger trash float-right" onClick={() => {remove(index)}}>
                                        <FontAwesomeIcon icon="trash" />
                                    </button>
                                </div>
                                <PhotoAttachmentArray
                                    nestIndex={index}
                                    namePrefix={formTextFieldArrayName}
                                    handleMediaChange={handleMediaChange}
                                    handleMediaDelete={handleMediaDelete}
                                    {...{ control, register, setValue, getValues }}
                                />
                            </>
                        </div>
                    )
                })}

                <section className="mt-2">
                    <button type="button" className="btn btn-dark" onClick={() => {
                        append({
                            photo_attachments_attributes: []
                        })
                    }}>
                        <FontAwesomeIcon icon="plus" /> Lisää kommentti
                    </button>
                </section>
            </div>
        </>
    )
}
