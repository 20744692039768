import React, { Component } from "react";
import logo from './../../logo.png';
import "./Header.css";
import classnames from 'classnames';

class Header extends Component {
    render() {
        const notifyclass = classnames('notify', {
            danger: this.props.offline
        });
        const message = this.props.offline ?
            `
            Olet offline-moodissa.
        ` :
            `
            
        `;
        return (
            <div className={notifyclass}>
                <img src={logo} className="App-logo" alt="Infomaatti Logo" />
                <p>
                    <em>{message}</em>
                </p>
            </div>
        );
    }
}

export default Header;
