import React from 'react'
import MaterialTable from 'material-table'
import materialTableLocalizations from '../../../config/materialTableI18N'
import getCardList from '../../../services/Cards'
import 'bootstrap/dist/css/bootstrap.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import localForage from 'localforage'

class FaultAndDefect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            current_user: null,
            current_project: null,
            current_customer: null,
            card_list: [],
            table_icons: materialTableLocalizations.tableIcons,
            table_i18n: materialTableLocalizations.fi,
            selected_customer_id: -1,
            selected_project_id: -1,
            selected_card: null
        }
    }

    openCard(selectedRow) {
        const index = this.state.card_list.findIndex(card => card.uid === selectedRow.uid)
        this.setState({ selected_card: JSON.stringify(this.state.card_list[index]) })
    }

    setCurrentCustomer(customer) {
        if (this.state.current_user && customer) {
            this.setState({ current_customer: customer, selected_customer_id: customer.customer_id })
        }
    }

    setCurrentProject(project) {
        if (project) {
            getCardList(project.id, this.state.current_user.run, 'fault_and_defect_lists')
            .then((response) => response.json())
            .then((result) => this.setState({ card_list: result }))
            .catch((e) => {
                console.log('ERR!')
                console.log(e)
            })
            this.setState({ current_project: project, selected_project_id: project.id })
        }
    }

    componentDidMount() {
        localForage.getItem('current_user')
        .then( value => this.setState({ current_user: value }) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_customer')
        .then( value => this.setCurrentCustomer(value) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_project')
        .then( value => this.setCurrentProject(value) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })

    }

    render() {
        if (this.state.current_user === null)
            return null

        const selected_card_data = this.state.selected_card ? this.state.selected_card : ''
        const project = this.state.current_project ? this.state.current_project.name : 'Ei valittu'
        const card_list_columns = [
            { title: 'Päivämäärä', field: 'inspection_date' },
            { title: 'Aihe', field: 'subject' }
        ]
        var card_list_rows = []
        if (this.state.card_list) {
            const date_options = { day: 'numeric', month: 'numeric', year: 'numeric' }
            const locale_str = 'fi-FI'
            this.state.card_list.map(function(card, index) {           
                return card_list_rows.push({
                    inspection_date: new Date(card.inspection_date).toLocaleDateString(locale_str, date_options),
                    subject: card.subject,
                    uid: card.uid
                })
                
            })
        }

        return(
            <div>
                <h3>Projekti: {project}</h3>
                <Container className="mt-4">
                    <Row>
                        <Col xs={12}>
                            <a href="/cards/fdl/form" className="btn card-btn" role="button">Luo uusi Virhe- ja puutelista</a>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <MaterialTable 
                                columns={card_list_columns}
                                data={card_list_rows}
                                icons={this.state.table_icons}
                                title="Virhe- ja puutelistat"
                                onRowClick={((evt, selectedRow) => this.openCard(selectedRow))}
                                options={{
                                    rowStyle: x => {
                                        if ( x.tableData.id % 2 ) {
                                            return { backgroundColor: "#f2f2f2" }
                                        }
                                    },
                                    headerStyle : {},
                                    selectionProps: rowData => ({
                                        checked: rowData.id === this.state.selected_customer_id
                                    })
                                    
                                }}
                                localization={this.state.table_i18n}
                            />
                        </Col>  
                    </Row>
                </Container>
                <div id="selectedCard" className="mt-2 mb-2">{selected_card_data}</div>
            </div>
        );
    }
}

export default FaultAndDefect
