import React, { Component } from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import localForage from 'localforage'
import Login from './components/Login'
import Logout from './components/Login/Logout'
import Password from './components/Login/NewPassword'
import NavBar from './components/NavigationBar'
import Header from './components/Header'
import Projects from './components/Projects'
import Cards from './components/Cards'
import FaultAndDefectList from './components/Cards/FaultAndDefect'
import FaultAndDefectListForm from './components/Cards/FaultAndDefect/New'
import User from './components/User'
import ErrorBoundary from './components/ErrorBoundary'
import Home from './pages/Home'
import About from './pages/About'

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            offline: false,
            current_user: null
        }
    }

	setCurrentUser(user) {
        if (user) {
            this.setState({ current_user: user })
        }
    }

    componentDidMount() {
        window.addEventListener('online', () => {
            this.setState({ offline: false });
        });

        window.addEventListener('offline', () => {
            this.setState({ offline: true });
        });

        localForage.getItem('current_user')
        .then((value) => this.setCurrentUser(value))
        .catch(function(err) {
            console.log('ERR!: '+err)
        })
    }

    login = (user) => {
        console.log('logged in')
        console.log(user)

        window.location.href = '/projects'
    }

    logout = () => {
        this.setState({ current_user: null })
//        window.location.href = '/'       

    }

    componentDidUpdate() {
        let offlineStatus = !navigator.onLine
        if (this.state.offline !== offlineStatus) {
            this.setState({ offline: offlineStatus })
        }
    }

    render() {
        const user = this.state.current_user

        return (
            <ErrorBoundary>
                <div className="App">
                    <Header offline={this.state.offline} />
                    <Router>
                        <NavBar current_user={user} logout={this.logout} />
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/login' component={() => (<Login signIn={this.login} />)} />
                            <Route exact path='/logout' component={() => (<Logout logout={this.logout} />)} />
                            <Route exact path='/new_password' component={Password} />
                            <Route exact path='/about' component={About} />
                            <Route exact path='/projects' component={Projects} />
                            <Route exact path='/user' render={(props) => (<User {...props} current_user={user} />)} />
                            <Route exact path='/cards' component={Cards} />
                            <Route exact path='/cards/fdl' component={FaultAndDefectList} />
                            <Route exact path='/cards/fdl/form' render={(props) => (<FaultAndDefectListForm {...props} offline={this.state.offline} />)} />
                        </Switch>
                    </Router>
                </div>
            </ErrorBoundary>
        );
    }
}

export default App;
