import infomaattiAPI from './../config/api';
import base64 from 'base64-min';

function token(email, pass) {
    return base64.encode(email + ":" + pass)
}

function login(token) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + token
    }

    var myHeaders = new Headers()
    for (var key in headers) {
        myHeaders.append(key, headers[key])
    }

    const url = infomaattiAPI.baseURL + 'managers/authenticate_returning_customers'
    const request = new Request(url, {
        method: 'GET',
        headers: myHeaders,
        cache: 'no-store'
    })

    return fetch(request)
}

export default {token, login}
