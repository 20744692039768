const developmentServer = 'http://localhost:3000'

// TODO: change back to selain address
//const productionServer = 'https://selain.infomaatti.fi'
const productionServer = 'https://demo.infomaatti.fi'

const infomaattiAPI = {
    baseURL: ( process.env.NODE_ENV === 'production' ? productionServer : developmentServer ) + '/api/v2/',
    cacheName: 'infomaattiAPI',
    cache: 'default'
}

export default infomaattiAPI
