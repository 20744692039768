import React, { Component } from 'react'
import localForage from 'localforage'
import 'bootstrap/dist/css/bootstrap.css'
import './Cards.css'

class Cards extends Component {
    constructor(props) {
        super(props)

        this.state = {
            current_user: null,
            current_project: null,
            current_customer: null
        }
    }

    componentDidMount() {
        localForage.getItem('current_user')
        .then( value => this.setState({ current_user: value }) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_customer')
        .then( value => this.setState({ current_customer: value }) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_project')
        .then( value => this.setState({ current_project: value }) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
    }

    render() {
        if (this.state.current_user === null) {
            return null
        }

        return(
            <div>
                <h3>Lomakkeet</h3>
                <div className="row">
                    <div className="col-12">
                        <a href="/cards/fdl" className="btn card-btn" role="button">Virhe- ja puutelista</a>                    
                    </div>
                </div>
            </div>
        )
    }
}

export default Cards


