import React from 'react'
import localForage from 'localforage'

class Logout extends React.Component {

    doLogout() {
        localForage.removeItem('current_user')
        .then( value => this.props.logout() )
    }

    componentDidMount() {
        localForage.getItem('current_user')
        .then( value => this.doLogout() )
    }

    render() {
        return null
    }

}

export default Logout