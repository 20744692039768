import React from "react"
import { Link } from 'react-router-dom'
import "./NavigationBar.css"

class NavBar extends React.Component {

    render() {
        const user = this.props.current_user

        if (user) {
            return (
                <div className="navi orange">
                    <li>
                        <Link to="/">Pääsivu</Link>
                    </li>
                    <li>
                        <Link to="/cards">Lomakkeet</Link>
                    </li>
                    <li>
                        <Link to="/projects">Projektit</Link>
                    </li>
                    <li>
                        <Link to="/user">{user.name}</Link>
                    </li>
                    <li>
                        <Link to="/about">Tietoja</Link>
                    </li>

                </div>
            )
        }

        return (
            <div className="navi orange">
                <li>
                    <Link to="/login">Kirjaudu sisään</Link>
                </li>
                <li>
                    <Link to="/">Pääsivu</Link>
                </li>
                <li>
                    <Link to="/about">Tietoja</Link>
                </li>
            </div>
        )
    }
}

export default NavBar
