import React from 'react';
const About = () => {
	return (
		<div>
			<h3>Infomaatti mobiili</h3>
			<div>
				Tämä on demoversio mobiilisovelluksesta. Demoversio kattaa vain Virhe- ja puutelistan.
				 <p>&copy; Infomaatti Oy</p>
			</div>
		</div>
	);
}
export default About;
