import React from 'react'
import MaterialTable from 'material-table'
import materialTableLocalizations from '../../config/materialTableI18N'
import getProjectList from './../../services/Project'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import localForage from 'localforage'

import 'bootstrap/dist/css/bootstrap.css'

class Projects extends React.Component {
	_isMounted = false

	constructor(props) {
		super(props)

		this.state = {
            current_user: null,
            current_project: null,
            current_customer: null,
            project_list: [],
            table_icons: materialTableLocalizations.tableIcons,
            table_i18n: materialTableLocalizations.fi,
            selected_customer_id: -1,
            selected_project_id: -1,
            project_table_title: 'Valitse ensin yritys'
		}
	}

 	findCustomerData(customer_id, customer_name) {
        var customer_data = {}
        if (this.state.current_user.all_accesses === true) {
            customer_data = {
                customer_id: customer_id,
                manager_id: this.state.current_user.id,
                name: customer_name
            }
        } else {
            const customer_accesses = this.state.current_user.customer_accesses || [];
            customer_data = customer_accesses.find(ca => ca.customer_id === customer_id)
            const customers = this.state.current_user['customers'] || []
            const customer = customers.find(c => c.id === customer_id)
            customer_data['name'] = customer.name
        }
        return customer_data
    }

	initializefromStorage(user, customer, project, projectList) {
		if (!user) {
			return
		}

		var cid = -1
		var pid = -1

		if (customer) {
			cid = customer.customer_id
			if (project) {
				pid = project.id
			}
		} else {
			project = null
		}

		this.setState({
			current_user: user,
			current_customer: customer,
			selected_customer_id: cid,
			current_project: project,
			selected_project_id: pid,
			project_list: projectList.projects || []
		})

	}

    fetchProjectList = async(customer, userToken) => {
        const cid = customer.customer_id
        return getProjectList(cid, userToken)
    }


	componentDidMount() {
		this._isMounted = true

		localForage.getItem('current_user')
		.then( (user) => {
			localForage.getItem('current_customer')
			.then( (customer) => {
				localForage.getItem('current_project')
				.then ((project) => {
					if (customer === null) {
						this.initializefromStorage(user, customer, project, [])
					} else {
						this.fetchProjectList(customer, user.run)
						.then((response) => response.json())
						.then((projectList) => this.initializefromStorage(user, customer, project, projectList))
					}
				})
			})
		})
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	setSelectedProjectRow(selectedRow) {
		const project = this.state.project_list.find(pr => pr.id === selectedRow.id)
		localForage.setItem('current_project', project)
		this.setState({ current_project: project, selected_project_id: selectedRow.id })
	}

    setSelectedCustomerRow(selectedRow) {
        const customer_data = this.findCustomerData(selectedRow.id, selectedRow.name)
        localForage.setItem('current_customer', customer_data)
        localForage.removeItem('current_project')
        this.fetchProjectList(customer_data, this.state.current_user.run)
        .then((response) => response.json())
        .then((projectList) => {
            this.setState({
                current_customer: customer_data,
                selected_customer_id: selectedRow.id,
                current_project: null,
                selected_project_id: -1,
                project_list: projectList.projects || []
            })
        })
        .catch((e) => {
            console.log('ERR while fetching project list!')
            console.log(e)
        })
    }

	render() {
		if (this.state.current_user === null)
			return null

		const customer = this.state.current_customer ? this.state.current_customer.name : 'Ei valittu'
		const project = this.state.current_project ? this.state.current_project.name : 'Ei valittu'
		const select_project_title = this.state.project_table_title

		const select_customer_columns = [
			{ title: 'Yritys', field: 'name' },
		]

		var select_customer_rows = []
		this.state.current_user.customers.map(function(customer, index) {
			return select_customer_rows.push({name: customer.name, id: customer.id })
		})

		const select_project_columns = [
			{ title: 'Projekti', field: 'name'}
		]

		var select_project_rows = []
		if (this.state.selected_customer_id > 0) {
       		this.state.project_list.map(function(project, index) {
				return select_project_rows.push({name: project.name, id: project.id})
           	})
       	}

		return(
			<div>
				<h3>Projektit</h3>
				<div>
					Yritys: {customer}
				</div>
				<div>
					Projekti: {project}
				</div>
				<Container className="mt-4">
					<Row>
						<Col xs={12} lg={6}>
							<MaterialTable 
								columns={select_customer_columns}
								data={select_customer_rows}
								icons={this.state.table_icons}
								title="Valitse yritys"
								onRowClick={((evt, selectedRow) => this.setSelectedCustomerRow(selectedRow))}
								options={{
                       				rowStyle: x => {
                       					if ( x.id === this.state.selected_customer_id ) {
	                       					return { backgroundColor: "#FF5733" }
    	                   				}
        	                   			if ( x.tableData.id % 2 ) {
            	               				return { backgroundColor: "#f2f2f2" }
                	           			}
                    	   			},
                       				headerStyle : {},
									selectionProps: rowData => ({
       									checked: rowData.id === this.state.selected_customer_id
    								})
                       			}}
                                localization={this.state.table_i18n}
							/>
						</Col>	
						<Col xs={12} lg={6}>
							<MaterialTable 
								columns={select_project_columns}
								data={select_project_rows}
								icons={this.state.table_icons}
								title={select_project_title}
								onRowClick={((evt, selectedRow) => this.setSelectedProjectRow(selectedRow))}
								options={{
                       				rowStyle: x => {
                       					if ( x.id === this.state.selected_project_id ) {
                       						return { backgroundColor: "#FF5733" }
                       					}
                           				if ( x.tableData.id % 2 ) {
                           					return { backgroundColor: "#f2f2f2" }
                           				}
                       				},
                       				headerStyle: {},
                       				selectionProps: rowData => ({
       									checked: rowData.id === this.state.selected_project_id
    								})
                       			}}
                                localization={this.state.table_i18n}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Projects
