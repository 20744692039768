import React from 'react';
const Home = () => {
	return (
		<div>
			<h3>Pääsivu</h3>
			<div className="content">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet luctus augue. Nunc dui nisl, aliquet eget convallis nec, fringilla quis nisl. Vivamus dui odio, convallis vel massa mollis, efficitur condimentum arcu. Curabitur efficitur quis augue ut interdum. Pellentesque iaculis interdum est, at eleifend arcu gravida facilisis. In hendrerit porta euismod. Maecenas et viverra ex. In hac habitasse platea dictumst. Integer molestie ac lectus in faucibus. Nulla vulputate ultricies justo eget placerat.
			</div>
		</div>
	);
}
export default Home;
