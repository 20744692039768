import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import uuid from 'uuid'
import fi from 'date-fns/locale/fi'
import localForage from 'localforage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSpin } from '@fortawesome/fontawesome-free-solid'
import Popover from 'react-bootstrap/Popover'
import FaultAndDefectRowArray from '../recipes/FaultAndDefectRowArray'
import { saveNewCard, addPhoto } from '../../../services/Cards'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../Cards.css'

registerLocale('fi', fi)

export default function FaultAndDefectForm(props) {
    const [userToken, setUserToken] = useState()
    const [projectId, setProjectId] = useState()
    const [mediaFiles, setMediaFiles] = useState([])
    const [newCards, setNewCards] = useState(new Map())
    const [isSaving, setIsSaving] = useState(false)

    const defaultFormValues = {
        subject: '',
        header_text: 'VIRHE- JA PUUTELISTA',
        inspection_date: new Date(),
        fault_and_defect_rows_attributes: [
            {
                uid: uuid(),
                apartment_name: '',
                room_category: '',
                fault: '',
                contractor: '',
                checked: false,
                checked_at: null,
                to_be_repaired: false,
                additional_work: false,
                no_action_needed: false,
                form_text_fields_attributes: []
            }
        ]
    }

    const { register, control, handleSubmit, reset, trigger, defaultValues, getValues, setValue, setError, formState: { errors } } = useForm({ 
                    defaultValues: defaultFormValues,
                    criteriaMode: 'all'
    })

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: ""
    })

    useEffect(() => {
        if ( newCards.size > 0 ) {
            setNotificationMessage('Tallennettuja raportteja lähetetään palvelimelle...')
            var cards = newCards
            cards.forEach((value, key) => {
                var result = sendCardDataToServer(value, key)
                console.log('result', result)
            })
        }
        sendLocallySavedDataToServer()
    })

    const fetchUserToken = () => {
        localForage.getItem('current_user')
        .then( value => setUserToken(value.run) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
    }

    const fetchProjectId = () => {
        localForage.getItem('current_project')
        .then( value => setProjectId(value.id) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
    }

    const fetchSavedCards = () => {
        localForage.getItem('saved_cards')
        .then( value => console.log('saved cards', value) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
    }


    const setNotificationMessage = (msg) => {
        var notificationElement = document.getElementsByClassName('notification-message')[0]
        notificationElement.innerHTML = msg
        setTimeout(() => notificationElement.innerHTML = "", 5000)
    }

    const saveCardDataLocally = (data, serverPath) => {
        var cardData = {
            project: projectId,
            path: serverPath,
            formData: data,
            mediaFiles: []
        }

        Object.values(mediaFiles).forEach((mediaFileArray) => {
            mediaFileArray.forEach((mediaFile) => {
                cardData.mediaFiles.push(mediaFile)
            })
        })

        var savedCards = newCards
        var timestamp = Date.now()
        savedCards.set(timestamp, cardData)

        localForage.getItem('saved_cards')
        .then( (value) => {
            var forageCards = value || new Map()
            forageCards.set(timestamp, cardData)
            localForage.setItem('saved_cards', forageCards)
        })
        .catch(function(err) {
            console.log('ERR saving card data locally!: ')
            console.log(err)
        })

        setNewCards(savedCards)


/*
        localForage.getItem('saved_cards')
        .then( (value) => {
            var savedCards = value || new Map()
            savedCards.set(Date.now(), cardData)

            localForage.setItem('saved_cards', savedCards)
            .then(() => sendLocallySavedDataToServer)
        })
        .catch(function(err) {
            console.log('ERR saving card data locally!: ')
            console.log(err)
        })
*/        
    }

    const sendMediaDataToServer = (projectId, cardId, path, mediaFileArray) => {
console.log('mediafiles', mediaFileArray)

        if (mediaFileArray === undefined || mediaFileArray.length === 0) {
            return null
        }

        var media = mediaFileArray
        media.forEach((mediaFile) => {
            const mediaFormData = new FormData()
            mediaFormData.append('image_file', mediaFile)
            addPhoto(projectId, userToken, path, cardId, mediaFormData)
            .then((val) => {
                mediaFileArray.splice(0, 1) // delete successfully sent
            })
            .catch((e) => {
                console.log('File upload ERR!')
                console.log(e)
                return mediaFileArray
            })
        })
        return mediaFileArray
    }

    const sendCardDataToServer = (data, key) => {
        if (data === undefined) {
            return null
        }

        // card data has already been sent successfully
        if (data['cardId'] !== undefined) {
            console.log('card data sent, now sending mediafiles', data.mediaFiles)

            sendMediaDataToServer(data.project, data.cardId, data.path, data.mediaFiles)
        }

        saveNewCard(data.project, userToken, data.path, data.formData)
        .then((response) => response.json())
        .then((card_id) => {
            console.log('created card id', card_id)
            data.cardId = card_id

            var mediaDataLeft = sendMediaDataToServer(data.project, data.cardId, data.path, data.mediaFiles)
            if (mediaDataLeft === null) {
                var cards = newCards
                cards.delete(key)
                localForage.getItem('saved_cards')
                .then( (savedCards) => {
                    if (savedCards && savedCards.has(key)) {
                        savedCards.delete(key)
                        localForage.setItem('saved_cards', savedCards)
                    }
                })
                setNewCards(cards)
            } else {
                data.mediaFiles = mediaDataLeft
                var cards = newCards
                cards.set(key, data)
                localForage.getItem('saved_cards')
                .then( (savedCards) => {
                    if (savedCards) {
                        savedCards.set(key, data)
                        localForage.setItem('saved_cards', savedCards)
                    }
                })
                setNewCards(cards)
//                return data
            }
        })
        .catch(function(err) {
            console.log('ERR! while sending card data to server', err)
            return data
        })
    }

    const sendLocallySavedDataToServer = () => {
        // not worth to even try if user is offline
        if (props.offline === true)
            return

        localForage.getItem('saved_cards')
        .then( (savedCards) => {
            savedCards = savedCards || new Map()
            savedCards.forEach((value, key) => {
                sendCardDataToServer(value, key)
            })
/*
                var cardData = sendCardDataToServer(value, key)
                if (cardData === null) {
                    savedCards.delete(key)
                } else {
                    savedCards.set(key, cardData)
                }
            })
*/            
//            localForage.setItem('saved_cards', savedCards)
//            .then( () => console.log('db updated with saving cards', savedCards))
        })
    }

    const onSubmit = (data) => {
        if (props.offline === true) {
            setNotificationMessage('Olet offline-tilassa. Raportti tallennetaan paikallisesti ja lähetetään palvelimelle automaattisesti, kun palaat online-tilaan ja tulet tälle uudestaan tälle sivulle.')
        } else {
            setNotificationMessage('Raportti lähetetään palvelimelle...')
        }

        saveCardDataLocally(data, 'fault_and_defect_lists')
    }

    const checkSavedCards = () => {
        console.log('from state', newCards)

        var saved = fetchSavedCards()
        console.log('locally saved cards', saved)
        sendLocallySavedDataToServer()
        saved = fetchSavedCards()
        console.log('locally saved cards after sending', saved)
    }


    if (!userToken) fetchUserToken()
    if (!projectId) fetchProjectId()

    const handleMediaChange = (event, formName) => {
        var updateMediaFiles = mediaFiles
        if (updateMediaFiles[formName] === undefined) {
            updateMediaFiles[formName] = Array.from(event.target.files)
        } else {
            updateMediaFiles[formName] = Array.from(updateMediaFiles[formName]).concat(Array.from(event.target.files))
        }
        setMediaFiles(updateMediaFiles)
    }

    const handleMediaDelete = (event, formName, index) => {
        var updateMediaFiles = mediaFiles
        if (updateMediaFiles[formName] === undefined)
            return

        if (updateMediaFiles[formName].length < index)
            return

        updateMediaFiles[formName].splice(index, 1)
        setMediaFiles(updateMediaFiles)
    }




    if (!userToken)
        return null

    return (
        <>
        {console.log('props', props)}
        {console.log('fields', fields)}
            <h1>Uusi Virhe- ja puutelista</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Controller
                        render={({ field }) => <input {...field} type="hidden" />}
                        className="form-control"
                        name="uid"
                        control={control}
                        defaultValue={uuid()}
                    />
                </div>
                <div className="form-group required">
                    <label htmlFor="subject" className="col-form-label">Otsikko</label>
                    <Controller
                        render={({ field }) => <input {...field} />}
                        className="form-control string"
                        name="subject"
                        rules={{
                            required: 'Otsikko on pakollinen'
                        }}
                        control={control}
                    />
                    { errors.subject && errors.subject.type === 'required' && <p className="alert alert-danger required-alert">{errors.subject.message}</p> }
                </div>

                <div className="form-group date-picker">
                    <label htmlFor="inspection_date" className="col-form-label">Päiväys</label>
                    <Controller
                        render={({ field }) => (
                            <DatePicker
                                placeHolderText='Valitse tarkastuspäivämäärä'
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                dateFormat="d.M.Y"
                                locale="fi"
                            />
                        )}
                        name="inspection_date"
                        control={control}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="header_text" className="col-form-label">Raportin ylätunniste</label>
                    <Controller
                        render={({ field }) => <input {...field} />}
                        className="form-control string"
                        name="header_text"
                        control={control}
                    />
                </div>
                
                <div className="mb-4" />

                <FaultAndDefectRowArray
                    handleMediaChange={handleMediaChange}
                    handleMediaDelete={handleMediaDelete}
                    {...{ control, register, defaultValues, getValues, setValue, errors }}
                />

{console.log('isSaving', isSaving)}

                <div className="btn-group mt-5">
                    <button type="button" className="btn btn-danger me-5" onClick={() => reset() } >
                        Tyhjennä
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Tallenna
                    </button>
                </div>
            </form>
            <div className="pt-2 pb-2 notification-message"></div>
        </>
    )
}
