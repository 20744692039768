import React, { Component } from "react"
import localForage from 'localforage'

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current_user: null,
            current_project_name: null,
            current_customer_name: null
        };
    }

    setCustomerName(customer) {
        if (customer) {
            this.setState({ current_customer_name: customer.name })
        }
    }

    setProjectName(project) {
        if (project) {
            this.setState({ current_project_name: project.name })
        }
    }

    componentDidMount() {
        localForage.getItem('current_user')
        .then( value => this.setState({ current_user: value }) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_customer')
        .then( value => this.setCustomerName(value) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
        localForage.getItem('current_project')
        .then( value => this.setProjectName(value) )
        .catch(function(err) {
            console.log('ERR!: ')
            console.log(err)
        })
  	}

	render() {
        if (this.state.current_user === null)
            return null

        const user = this.state.current_user
        const name = user.name || ''
        const company = user.company || ''
        const profession = user.job_title || ''
        const project = this.state.current_project_name || ''
        const customer = this.state.current_customer_name || ''

        return(
            <div>
                <h3>{name}</h3>
                <div>
                    <p>{profession}</p>
                    <p>{company}</p>
                    <p>Valittu asiakas: {customer}</p>
                    <p>Valittu projekti: {project}</p>
                </div>
                <div className="logout">
		        <a href="/logout">Kirjaudu ulos</a>
                </div>
            </div>
        );

    }
}

export default User;
