import React, { Component } from 'react'
import Authentication from './../../services/Authentication'
import {  Link } from 'react-router-dom'
import localForage from 'localforage'
import './Login.css'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            message: "",
            isLoading: "",
            current_user: null
        };
    };

    componentDidMount() {
        console.log('login mount')
        console.log(this.props)

        localForage.getItem('current_user')
        .then(function(value) {
            if (value) {
                console.log('got current_user')
                console.log(value)

                this.props.history.push('/')
            }

        }).catch(function(err) {
            console.log('got err: ' + err)
        })
    }
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({isLoading: "Vahvistetaan kirjautumista.."});
        const auth_token = Authentication.token(this.state.email, this.state.password)
        Authentication.login(auth_token)
        .then((response) => response.json())
        .then((data) => {
            data['run'] = auth_token
            const user_data = data
            localForage.setItem('current_user', user_data)
            this.props.signIn(user_data)
        })
        .catch((error) => {
            console.log('There was an error!');
            console.log(error);
            console.log('request:');
            console.log(error.request);

            if (error.response) {
                if (error.response.status === 401) {  // unauthorized
                    this.setState({message: "Virheellinen sähköpostiosoite tai salasana!"});
                    this.setState({isLoading: ""});
                    this.timeout();
                }
            } else if (error.request) { // request was made but server returned no response             
                console.log(error.request);
                this.setState({message: "Tapahtui odottamaton virhe!"});
                this.setState({isLoading: ""});
                this.timeout();
            } else {                    // something went wrong in setting up the request
                console.error('Error: ' + error.message);
                this.setState({message: "Tapahtui odottamaton virhe!"});
                this.setState({isLoading: ""});
                this.timeout();
            }
        })      
    }
    
    //timeout function
    timeout() {
        setTimeout(
            function() {
                this.setState({message: ""});
            }
            .bind(this),
            6000
        );
    }

    render() {
        return (
            <div>
                <div className="wrapper fadeInDown">
                    <div id="formContent">
                        <h2 className="active"><b>Kirjaudu sisään</b></h2>

                        <form onSubmit={this.onSubmit}>
                            <input type="email" name="email" value={this.state.email} onChange={this.onChange} placeholder="Sähköposti" />
                            <input type="password" name="password" value={this.state.password} onChange={this.onChange} placeholder="Salasana" />
                            <input type="submit" className="fadeIn fourth" value="Kirjaudu" />
                            <p>{this.state.isLoading}</p>
                            <p className="loginalert">{this.state.message}</p>  
                            <p>
                                <Link to="/new_password">Unohditko salasanasi?</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
