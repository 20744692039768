import infomaattiAPI from './../config/api'

async function getProjectList(customer_id, token) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + token,
        'Customer-Id': customer_id
    }

    var myHeaders = new Headers()
    for (var key in headers) {
        myHeaders.append(key, headers[key])
    }

    const url = infomaattiAPI.baseURL + 'projects'
    const request = new Request(url, {
        method: 'GET',
        headers: myHeaders,
        cache: 'no-store'
    })

    return await fetch(request)
}

export default getProjectList
