import React from 'react'
import { useFieldArray, Controller } from 'react-hook-form'
import uuid from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCameraRetro } from '@fortawesome/fontawesome-free-solid'
import Camera from '../../CameraPopup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default ({ nestIndex, namePrefix, handleMediaChange, handleMediaDelete, control, register, setValue, getValues }) => {
    const PhotoAttachmentArrayName = namePrefix + '.' + nestIndex + '.photo_attachments_attributes'

    const { fields, remove, append } = useFieldArray({
        control,
        name: PhotoAttachmentArrayName
    })

    return (
        <>
            <div className="photo-attachment-fields">
                {fields.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" value={field.value} />}
                                        className="form-control"
                                        name={`${PhotoAttachmentArrayName}.${index}.position`}
                                        control={control}
                                        defaultValue={index}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" value={field.value} />}
                                        className="form-control"
                                        name={`${PhotoAttachmentArrayName}.${index}.uid`}
                                        control={control}
                                        defaultValue={uuid()}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" value={field.value} />}
                                        className="form-control"
                                        name={`${PhotoAttachmentArrayName}.${index}.taken_at`}
                                        control={control}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        render={({ field }) => <input {...field} type="hidden" value={field.value} />}
                                        className="form-control"
                                        name={`${PhotoAttachmentArrayName}.${index}.original_filename`}
                                        control={control}
                                    />
                                </div>
                            
                                <Container className="mt-2">
                                    <Row>
                                        <Col xs={3} md={2}>
                                            <div className="form-group photo">
                                                <Controller
                                                    render={({ field }) => <img src={field.value} className="photo-preview" {...field} alt=""/>}
                                                    className="form-control"
                                                    name={`${PhotoAttachmentArrayName}.${index}.preview`}
                                                    control={control}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={7} md={9}>
                                            <div className="form-group photo-title">
                                                <Controller
                                                    render={({ field }) => <textarea rows="3" cols="30" {...field} />}
                                                    className="form-control"
                                                    name={`${PhotoAttachmentArrayName}.${index}.title`}
                                                    control={control}
                                                    defaultValue=""
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-0">
                                            <button type="button" className="btn btn-danger trash" onClick={(e) => {
                                                remove(index)
                                                handleMediaDelete(e, PhotoAttachmentArrayName, index)
                                            }}>
                                                <FontAwesomeIcon icon="trash" />
                                            </button>
                                        </Col>
                                    </Row>
                                </Container>
                   
                            </>
                        </div>
                    )
                })}

                <div className="photo-upload">
                    <FontAwesomeIcon icon="camera-retro" className="fa-2x" />
                    <input type="file" accept="image/*" className="upload" capture multiple
                        onChange={(e) => { 
                            Array.prototype.forEach.call(e.target.files, function(file) {
                                append({
                                    original_filename: file.name,
                                    taken_at: file.lastModifiedDate,
                                    uid: uuid(),
                                    preview: URL.createObjectURL(file)
                                })
                            })
                            handleMediaChange(e, PhotoAttachmentArrayName)
                        }}
                    />
                </div>
            </div>
        </>
    )
}
