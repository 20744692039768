import React from "react";
import { useFieldArray, Controller } from 'react-hook-form'
import { Checkbox } from '@material-ui/core'
import uuid from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faCopy } from '@fortawesome/fontawesome-free-solid'
import PhotoAttachmentArray from './PhotoAttachmentArray'
import FormTextFieldArray from './FormTextFieldArray'

export default function Fields({ handleMediaChange, handleMediaDelete, control, register, setValue, getValues }) {
    const fdlRowArrayName = "fault_and_defect_rows_attributes"

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: fdlRowArrayName
    });

    return (
        <>
            <div> 
                {fields.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <div>
                                <Controller
                                    render={({ field }) => <input {...field} type="hidden" />}
                                    className="form-control"
                                    name={`${fdlRowArrayName}.${index}.position`}
                                    control={control}
                                    defaultValue={index}
                                />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Huoneisto</label>
                                <Controller
                                    render={({ field }) => <input {...field} />}
                                    className="form-control"
                                    name={`${fdlRowArrayName}.${index}.apartment_name`}
                                    control={control}
                                />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Tila</label>
                                <Controller
                                    render={({ field }) => <input {...field} />}
                                    name={`${fdlRowArrayName}.${index}.room_category`}
                                    className="form-control"
                                    control={control}
                                />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Virhe/puute</label>
                                <Controller
                                    render={({ field }) => <input {...field} />}
                                    name={`${fdlRowArrayName}.${index}.fault`}
                                    className="form-control"
                                    control={control}
                                />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Toimenpide</label>
                                <Controller
                                    render={({ field }) => <Checkbox {...field} checked={field.value} />}
                                    name={`${fdlRowArrayName}.${index}.to_be_repaired`}
                                    className="form-control"
                                    control={control}
                                />
                                <label htmlFor={`${fdlRowArrayName}.${index}.to_be_repaired`} className="form-check-label">Korjataan</label>
                                <Controller
                                    render={({ field }) => <Checkbox {...field} checked={field.value} />}
                                    name={`${fdlRowArrayName}.${index}.additional_work`}
                                    className="form-control"
                                    control={control}
                                />
                                <label htmlFor={`${fdlRowArrayName}.${index}.additional_work`} className="form-check-label">Lisätyö</label>
                                <Controller
                                    render={({ field }) => <Checkbox {...field} checked={field.value} />}
                                    name={`${fdlRowArrayName}.${index}.no_action_needed`}
                                    className="form-control"
                                    control={control}
                                />
                                <label htmlFor={`${fdlRowArrayName}.${index}.no_action_needed`} className="form-check-label">Ei toimenpit.</label>            
                            </div>

                            <div className="form-group">
                                <label className="col-form-label">Urakoitsija</label>
                                <Controller
                                    render={({ field }) => <input {...field} />}
                                    name={`${fdlRowArrayName}.${index}.contractor`}
                                    className="form-control"
                                    control={control}
                                />
                            </div>

                        <PhotoAttachmentArray
                            nestIndex={index}
                            namePrefix={fdlRowArrayName}
                            handleMediaChange={handleMediaChange}
                            handleMediaDelete={handleMediaDelete}
                            {...{ control, register, setValue, getValues }}
                         />
                        <FormTextFieldArray
                            nestIndex={index}
                            namePrefix={fdlRowArrayName}
                            handleMediaChange={handleMediaChange}
                            handleMediaDelete={handleMediaDelete}
                            {...{ control, register, setValue, getValues }}
                        />

                        <button type="button" className="btn btn-secondary mt-4 mb-4 me-4" onClick={() => {
                                const row = getValues().fault_and_defect_rows_attributes[index]
                                const newRowData = {
                                    uid: uuid(),
                                    apartment_name: row.apartment_name,
                                    room_category: row.room_category,
                                    fault: row.fault,
                                    contractor: row.contractor,
                                    checked: row.checked,
                                    checked_at: row.checked_at,
                                    to_be_repaired: row.to_be_repaired,
                                    additional_work: row.additional_work,
                                    no_action_needed: row.no_action_needed,
                                    photo_attachments_attributes: [],
                                    form_text_fields_attributes: []
                                }

                                // insert the new row next to this one
                                setValue(fdlRowArrayName, [
                                    ...getValues().fault_and_defect_rows_attributes.slice(0, index+1),
                                    newRowData,
                                    ...getValues().fault_and_defect_rows_attributes.slice(index+1)
                                ])
                        }}>
                            <FontAwesomeIcon icon="copy" /> Kopioi rivi
                        </button>
                        
                        <button type="button" className="btn btn-danger" onClick={() => {remove(index)}}>
                            <FontAwesomeIcon icon="trash" />
                        </button>
                    </div>
                )})}
  
                <section>
                    <div className="btn-group-vertical">
                        <button type="button" className="btn btn-dark" onClick={() => {
                            setValue(fdlRowArrayName, [
                                ...getValues().fault_and_defect_rows_attributes,
                                {
                                    uid: uuid(),
                                    apartment_name: '',
                                    room_category: '',
                                    fault: '',
                                    contractor: '',
                                    checked: false,
                                    checked_at: null,
                                    to_be_repaired: false,
                                    additional_work: false,
                                    no_action_needed: false,
                                    photo_attachments_attributes: [],
                                    form_text_fields_attributes: []
                                }
                            ])
                        }}>
                            <FontAwesomeIcon icon="plus" /> Lisää rivi
                        </button>
                    </div>
                </section>

            </div>
        </>
    )
}
